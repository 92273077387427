import React, {Fragment} from 'react';

import Footer from '../../components/global/Footer';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';
import FeaturedCollections from '../../components/collections/FeaturedCollections';

/**
 * Collections page
 * @param options
 * @returns {*}
 * @constructor
 */
function Collections({ options }) {
  return (
    <Fragment>

      <Header options={options} />

      <PageTitle name="Collections"/>

      <FeaturedCollections/>

      <Footer/>

    </Fragment>
  )
}

export default Collections;
