import React, {Fragment} from 'react';
import { NavLink, useParams } from 'react-router-dom';

import Footer from '../../components/global/Footer';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';
import Tale from '../../components/tales/Tale';

import data from '../../data/featured-tales.json';

/**
 * Tale page
 * @param options
 * @returns {*}
 * @constructor
 */

function TalePage({ options }) {
  const { id } = useParams();

  // Find the featured tale data by ID
  const featuredTaleData = data.filter(item => item.id === parseInt(id))[0];

  const { title, description, header, conclusion } = featuredTaleData;

  return (
    <Fragment>

      <Header options={options} />

      <PageTitle name={title} />

      <section className="tales-section section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-12">
              <div className="tales-area">
                <div>
                  <h3 className='tales-header'>{header}</h3>
                  <p className="text-justify tales-introduction">
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Tale/>

      <section className="tales-section section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-12">
              <div className="tales-area">
                <div>
                  <p className="text-justify tales-introduction">
                    {conclusion}
                  </p>
                </div>
                <div className="text-center">
                  <NavLink to="/wholesale" className="theme-btn">Shop Online</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer/>

    </Fragment>
  )
}

export default TalePage;
