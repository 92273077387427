import React, {Fragment} from 'react';
import InstagramItem from "./InstagramItem";
import { NavLink } from 'react-router-dom';

/**
 * demo data
 */
const instagramItems = [
    {
        id:"1",
        img: "/assets/images/instagram/1.png",
        link: "https://www.instagram.com/p/CpFKLiqjvwc/"
    },
    {
        id:"2",
        img: "/assets/images/instagram/2.png",
        link: "https://www.instagram.com/p/Co_1oHOt3kL/"
    },
    {
        id:"3",
        img: "/assets/images/instagram/3.png",
        link: "https://www.instagram.com/p/Co9YDvXjEci/"
    },
    {
        id:"4",
        img: "/assets/images/instagram/4.png",
        link: "https://www.instagram.com/p/Co6-TAqtUJp/"
    },
    {
        id:"5",
        img: "/assets/images/instagram/5.png",
        link: "https://www.instagram.com/p/ConWxlPNgA7/"
    },
    {
        id:"6",
        img: "/assets/images/instagram/6.png",
        link: "https://www.instagram.com/p/Co4OtMljKBe/"
    }
];

/**
 * Instagram images component
 * @returns {*}
 * @constructor
 */
function Instagram() {

    return (
        <Fragment>
            {/* start instagram-section */}
            <section className="instagram-section">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="instagram-inner">
                                <div className="instagram-text">
                                    <h3>Follow our instagram</h3>
                                    <NavLink 
                                        to={""}
                                        onClick={() => window.open("https://www.instagram.com/_candeclothing/", "_blank")}
                                    >
                                        <p>@_candeclothing</p>
                                    </NavLink>
                                </div>
                                <div className="instagram-grids clearfix">
                                    {
                                        instagramItems.map((item, index) => (
                                            <InstagramItem key={index} link={item.link} img={process.env.PUBLIC_URL + item.img} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end instagram-section */}
        </Fragment>
    );
}

export default Instagram;
