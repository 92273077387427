import React, {Fragment} from 'react';

/**
 * Contact Widget
 * @returns {*}
 * @constructor
 */
function ContactWidget() {

    return (
        <Fragment>
            <div className="widget contact-widget">
                <h3>Contact info</h3>
                <ul>
                    <li><a href="mailto:hello@cande-clothing.com">hello@cande-clothing.com</a></li>
                    <li>Barcelona, Spain</li>
                </ul>
            </div>
        </Fragment>
    );
}

export default ContactWidget;
