import React, {Fragment} from 'react';
import { useParams } from 'react-router-dom';

import data from '../../data/featured-tales.json';

/**
 * Tale Component
 * @returns {*}
 * @constructor
*/

function Tale() {
  const { id } = useParams();

  // Find the featured tale data by ID
  const featuredTaleData = data.filter(item => item.id === parseInt(id))[0];

  return (
    <Fragment>
      {/* start featured-proeducts-section-s2 */}
      <section className="featured-proeducts-section-s2 single-tale-section-padding section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-lg-12">
              <div className="product-grids clearfix">
                {
                  featuredTaleData.images.map((img, index) => (
                    <div key={index} className="col-lg-4 tale-img">
                      <div className="img-holder">
                          <img loading="lazy" src={process.env.PUBLIC_URL + img} alt=""/>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        {/* end container-1410 */}
      </section>
      {/* end featured-proeducts-section-s2 */}
    </Fragment>
  );
}


export default Tale;
