import React, { Fragment } from 'react';
import Footer from '../../components/global/Footer';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';

import './concept.css';

/**
 * demo data
 */
import { NavLink } from "react-router-dom";

/**
 * Concept page
 * @param options
 * @returns {*}
 * @constructor
 */
function Concept({ options }) {

    return (
        <Fragment>
            <Header options={options} />

            <PageTitle name="Our Concept" />

            {/* start concept-section */}
            <section className="concept-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-5">
                            <div className="concept-area">
                                <div className="img-holder">
                                    <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/concept/_DSC0440.png"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-5">
                            <div className="mission-vision-area">
                                <div>
                                    <h3>Introducing Cande</h3>
                                    <p>
                                        A premier fashion brand that combines high-quality fabrics with Avant-grade fashion trends.
                                        Based in the heart of Barcelona, our brand is dedicated to creating unique and revolutionary
                                        fashion pieces that are full of personality, character, and innovation.
                                        At Cande, we believe that fashion is not just about following trends, but about pushing
                                        boundaries and expressing individuality. That's why we use only the finest fabrics and
                                        incorporate the latest fashion trends in our designs. Our signature details and novel techniques
                                        set us apart from other brands, while our individualistic appearance and unconventional approach
                                        give our customers a one-of-a-kind experience.
                                    </p>
                                </div>
                                <div>
                                    <h3>Collections</h3>
                                    <p>
                                        Our collections are created with the modern, fashion-forward individual in mind. We are constantly evolving
                                        and always looking for new ways to express ourselves. Whether you're looking for a statement piece or a
                                        timeless classic, Cande has something for everyone.
                                        Our team of skilled designers and artisans are committed to creating clothing that is not only stylish, but
                                        also comfortable and wearable. We are proud to say that our brand is synonymous with quality, craftsmanship,
                                        and innovation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NavLink to="" className="theme-btn initial-statement">What makes us different?</NavLink>

            <Fragment>
            {/* start breadcrumb */}
                <section className="page-title">
                    <div className="page-title-container">
                        <div className="page-title-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h3 className="breakline-title">Signature Details <br />and Novel Techniques</h3>
                                    </div>
                                </div>
                            </div>
                            {/* end container */}
                        </div>
                    </div>
                </section>
                {/* end breadcrumb */}
            </Fragment>

            <section className="concept-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-5">
                            <div className="concept-area">
                                <div className="img-holder">
                                <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/concept/_DSC0403.png"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-5">
                            <div className="mission-vision-area">
                                <div>
                                    <p>
                                        We focus on incorporating signature details and novel techniques into our designs, giving our clothing a one-of-a-kind look.
                                        Fashion trends are changing so fast, and we love to be at the forefront of trendsetters. By sticking to our brand, you will
                                        be able to create new trends and take them to greater heights. We got expert fashion designers working with us, who bring
                                        their expertise and experience to help us deliver the best to our clients at all times.
                                        Each item of clothing is crafted with an artisanal touch to ensure its distinct features.
                                        With Cande you can be sure you're wearing something truly special.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end concept-section */}

            <Fragment>
                {/* start breadcrumb */}
                <section className="page-title">
                    <div className="page-title-container">
                        <div className="page-title-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h3 className="breakline-title">Individualistic <br /> Appearance</h3>
                                    </div>
                                </div>
                            </div>
                            {/* end container */}
                        </div>
                    </div>
                </section>
                {/* end breadcrumb */}
            </Fragment>

            <section className="concept-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-5">
                            <div className="concept-area">
                                <div className="img-holder">
                                <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/concept/_DSC0442.png"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-5">
                            <div className="mission-vision-area">
                                <div>
                                    <p>
                                        Our clothing is designed to create an individualistic appearance, allowing the wearer to stand out
                                        and express their personal style. You truly deserve to look unique and different with your outfit,
                                        and we will be able to provide much-needed assistance to you with it. Anyone who wishes to take a
                                        break from traditional fashion trends can stick to us. It celebrates uniqueness and encourages people
                                        to express their own style, without compromising comfort or quality. Cande offers clothing for all
                                        occasions, from casual daywear to formal evening looks. Each piece is designed with the highest
                                        attention to detail and crafted with the best fabrics available, creating timeless pieces with a modern
                                        twist. You can be sure that your clothing will always make a statement!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end concept-section */}

            <Fragment>
                {/* start breadcrumb */}
                <section className="page-title">
                    <div className="page-title-container">
                        <div className="page-title-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h3 className="breakline-title">Unconventional <br /> Approach</h3>
                                    </div>
                                </div>
                            </div>
                            {/* end container */}
                        </div>
                    </div>
                </section>
                {/* end breadcrumb */}
            </Fragment>

            <section className="concept-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-5">
                            <div className="concept-area">
                                <div className="img-holder">
                                <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/_DSC0405-min.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-5">
                            <div className="mission-vision-area">
                                <div>
                                    <p>
                                        We also take an unconventional approach to fashion design, breaking away from traditional trends and conventions.
                                        Our team of expert fashion designers are not afraid to accept challenges. We also invest heavily on “Research & Development”,
                                        which helps us to discover new approaches to follow when designing new outfits. While following unconventional approaches,
                                        we also take customer feedback into consideration. This helps us to deliver products that cater to the best interests of our
                                        customers at all times. From unconventional cuts to a combination of high-quality fabrics, individualistic clothing is designed
                                        to stand out from the crowd while still being stylish and comfortable.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end concept-section */}

            <Fragment>
                {/* start breadcrumb */}
                <section className="page-title">
                    <div className="page-title-container">
                        <div className="page-title-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h3 className="breakline-title">Combination of <br /> High-Quality Fabrics</h3>
                                    </div>
                                </div>
                            </div>
                            {/* end container */}
                        </div>
                    </div>
                </section>
                {/* end breadcrumb */}
            </Fragment>

            <section className="concept-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-5">
                            <div className="concept-area">
                                <div className="img-holder">
                                    <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/IMG_1385.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-5">
                            <div className="mission-vision-area">
                                <div>
                                    <p>
                                        We use a combination of high-quality fabrics to ensure that our clothing is both stylish and durable. The amount you spend to buy
                                        products we offer is totally worth it. However, we ensure that you don’t have to spend unnecessarily to buy what we offer. Everything
                                        is offered to you at a price that you can afford. Browse our collections and discover
                                        the perfect addition to your store.
                                    </p>
                                </div>
                                <NavLink to="/wholesale" className="theme-btn">Experience the Cande difference for yourself</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end concept-section */}

            <NavLink to="/wholesale" className="theme-btn closing-statement">Embrace ultimate comfort and elegance with every piece from this revolutionary clothing brand – experience innovation at its finest!</NavLink>

            <Footer />
        </Fragment>
    );
}

export default Concept;
