import React, {Fragment} from 'react';
import { NavLink } from 'react-router-dom';

import Footer from '../../components/global/Footer';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';
import FeaturedTales from '../../components/tales/FeaturedTales';

/**
 * Tales page
 * @param options
 * @returns {*}
 * @constructor
 */
function Tales({ options }) {
  return (
    <Fragment>

      <Header options={options} />

      <PageTitle name="Tales"/>

      <section className="tales-section section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-12">
              <div className="tales-area">
                <div>
                  <h3 className='tales-header'>Tales of Cande</h3>
                  <p className="text-justify tales-introduction">
                    Unearth the hidden gems and untold narratives behind our collections. Dive deep into the stories that breathe
                    life into each product, as we unveil the inspirations, craftsmanship, and cultural influences that shape their
                    creation. Experience the magic that unfolds when art and storytelling intertwine, and let these tales transport
                    you to new realms of imagination and understanding.
                  </p>
                </div>
                <div className="text-center">
                  <NavLink to="/wholesale" className="theme-btn">Shop Online</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FeaturedTales/>

      <Footer/>

    </Fragment>
  )
}

export default Tales;
