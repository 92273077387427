import React, { Fragment } from 'react';
import Footer from '../../components/global/Footer';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';

import './cookies.css';

/**
 * demo data
 */

/**
 * Privacy page
 * @param options
 * @returns {*}
 * @constructor
 */
function CookiesPolicy({ options }) {

  return (
    <Fragment>
      <Header options={options} />

      <PageTitle name="Cookies Policy" />

      {/* start cookies-section */}
      <section className="cookies-section section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-12">
              <div className="cookies-list-area">
                <div>
                  <h3>
                    <span className='uppercase-span bold-span'>
                      By continuing to use our website and services, you are agreeing to our use of cookies and similar technologies as described in this policy. If you do not agree to our use of cookies and similar technologies, you must
                      disable them by changing your browser settings or stop using our website and services.
                    </span>
                  </h3>
                </div>
                <div>
                  <p>
                    <span className='bold-span'>
                      Cookies
                    </span>
                    <br />
                    A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. In general, cookies have two main purposes: to improve your browsing experience by remembering your actions
                    and preferences and to help us analyze our website traffic.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='bold-span'>
                      What to do with cookies?
                    </span>
                    <br />
                    We use cookies to help us analyze traffic to the Website, to help us improve website performance and usability, and to make the Website more secure. Third-party cookies help us use Google Analytics to count, track, and
                    analyze visits to the Website. This helps us understand how people are using our websites and where we need to make improvements. These third-party cookies do not specifically identify you.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='bold-span'>
                    Types & Category of Cookies used
                    </span>
                    <br />
                    List the category of cookies used in <span className='bold-span'>https://cande-clothing.com</span>. For example:
                    <br />
                    <br />
                    <ul className='cookies-list'>
                      <li>
                        <span className='bold-span'>Strictly Necessary Cookies</span>
                        <br />
                        We use cookies to enable and support our security features, and to help us detect malicious activity violations of our Terms and Conditions.
                      </li>
                      <li>
                        <span className='bold-span'>Performance Cookies</span>
                        <br />
                        Cookies help us learn how well our site and web products perform in different locations. We also use these to understand, improve, and research products, features, and services, including when you access this site
                        <span className='bold-span'> www.cande-clothing.com</span> from other websites, applications, or devices such as your work computer or your mobile device. We also use third-party cookies to improve and personalize our marketing messages/communications with you.
                      </li>
                    </ul>
                  </p>
                </div>
                <div>
                  <p>
                    <span className='bold-span'>
                      Control of Cookies
                    </span>
                    <br />
                    You are always free to delete cookies that are already on your computer through your browser settings, and you can set most browsers to prevent them from being added to your computer. However, this may prevent you from
                    using certain features on the Website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end cookies-section */}

      {/* start footer */}

      <Footer />

      {/* end footer */}
    </Fragment>
  );
}

export default CookiesPolicy;
