import React, {Fragment} from 'react';

/**
 * Company Widget
 * @returns {*}
 * @constructor
 */
function CompanyWidget() {

    return (
        <Fragment>
            <div className="widget company-widget">
                <h3>Company</h3>
                <ul>
                    <li><a href="/about">Philosophy</a></li>
                    <li><a href="/concept">Concept</a></li>
                    <li><a href="/collections">Collections</a></li>
                    <li><a href="/tales">Tales</a></li>
                </ul>
            </div>
        </Fragment>
    );
}

export default CompanyWidget;
