import React, {Fragment} from 'react';
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';

import '../products/lookbooks.css';

/**
 * demo data
 */
import lookbooksData from '../../data/lookbooks-ss24.json';
import {Link} from "react-router-dom";

/**
 * Recent Lookbooks component
 * @param onQuickViewClick
 * @returns {*}
 * @constructor
 */
function Lookbooks({onQuickViewClick}) {

    /**
     * slider settings
     */
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        focusOnSelect: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Fragment>
            {/* start trendy-product-section */}
            <section className="trendy-product-section section-padding">

                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="section-title-s2">
                                <h2>SS24</h2>
                            </div>
                            <Link className="more-products" to="/ss24-collection">
                                Discover More
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="products-wrapper">
                                <ul className="lookbooks product-row-slider">
                                    <Slider {...settings}>
                                        {
                                            lookbooksData.map((item, index) => (
                                                <li key={index} idx={index + 1} className="product">
                                                    <div className="product-holder">
                                                        <Link to={`/single-slider-lookbook/${index + 1}`} className="product">
                                                            <img loading="lazy" src={process.env.PUBLIC_URL + item.mainImg} alt=""/>
                                                        </Link>

                                                        {/* <div className="shop-action-wrap">
                                                            <ul className="shop-action">
                                                                <li><a href="#" title="Quick view"
                                                                       data-tip="Quick view"
                                                                       onClick={
                                                                           e => onQuickViewClick(e, item)
                                                                       }
                                                                ><i className="fi flaticon-view"/></a>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                    <div className="product-info">
                                                        <h4 className="lookbook-title">
                                                            <Link to={`/single-slider-lookbook/${index + 1}`}>
                                                                {item.title}
                                                            </Link>
                                                        </h4>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </Slider>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container-1410 */}
            </section>
            {/* end trendy-product-section */}
            <ReactTooltip className='tool-tip' effect='solid'/>
        </Fragment>
    );
}

export default Lookbooks;
