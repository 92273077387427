import React, { Fragment} from 'react';

import Footer from '../../components/global/Footer';
import Instagram from '../../components/global/Instagram';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';
import CtaSection from '../../components/CTA/CtaSection';

/**
 * Shop page Coming Soon
 * @param options
 * @returns {*}
 * @constructor
 */

function ShopComingSoon({ options }) {
  return (
    <Fragment>
      <Header
        options={options}
      />
      <PageTitle name="Shop"/>
      <div className="section-padding">
        <CtaSection/>
      </div>
      <Instagram/>
      <Footer/>
    </Fragment>
  );
}

export default ShopComingSoon;
