import React, { Fragment } from 'react';
import Footer from '../../components/global/Footer';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';

import './privacy.css';

/**
 * demo data
 */
import { NavLink } from "react-router-dom";

/**
 * Privacy page
 * @param options
 * @returns {*}
 * @constructor
 */
function PrivacyPolicy({ options }) {

  return (
    <Fragment>
      <Header options={options} />

      <PageTitle name="Privacy Policy" />

      {/* start privacy-section */}
      <section className="privacy-section section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-12">
              <div className="privacy-list-area">
                <div>
                  <h3>Last updated [January 31st, 2023]</h3>
                  <p>
                    We respect the privacy of our users and every person who visits our site. Here, <span className='bold-span'>”Website”</span> or <span className='bold-span'>“Site”</span> shall mean and include <span className='bold-span'>"https://cande-clothing.com”</span>,
                    and any of our successor Websites; and <span className='bold-span'>“We”</span>, <span className='bold-span'>“us”</span>, and <span className='bold-span'>“our”</span> are references to <span className='bold-span'>Cande-Clothing.</span>
                    We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regard to your personal information, please contact us at <span className='bold-span'>hello@cande-clothing.com</span>.
                    When you visit our website www.cande-clothing.com (“Site”) and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you
                    in the clearest way possible what information we collect, how we use it, and what rights you have about it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not
                    agree with, please discontinue the use of our site and our services.
                  </p>
                </div>
                <div>
                  <h3>About us</h3>
                  <p>
                    At cande-clothing.com, we offer you a meticulously designed website where we provide information about men avant-garde fashion brands.
                    <br />
                    <span className='bold-span'>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us. </span>
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>1. what information do we collect?</span>
                  </h3>
                  <p>
                    We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Site, or otherwise contacting us.
                    The personal information that we collect depends on the context of your interactions with us and the Site, the choices you make, and the products and features you use. 
                    <br />
                    <br />
                    <span className='uppercase-span'>The personal information we collect can include the following:</span>
                    <ul className='privacy-list'>
                    <br/>
                      <li>
                        <span className='bold-span'>Name and Contact Data.</span> We collect your first and last name, email address, and other similar contact data.
                      </li>
                      <li>
                        <span className='bold-span'>Information automatically collected.</span> We automatically collect certain information when you visit, use or navigate the Site. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address,
                        browser, and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Site and other technical information.  If you access our site with your mobile device,
                        we may automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information, and IP address. This information is primarily needed to maintain the security and operation of our Site, and
                        for our internal analytics and reporting purposes.
                        Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our 
                        <NavLink to={""} className='cookies-link'>
                          <span className='bold-span'>{" Cookie Policy"}</span>
                        </NavLink>.
                      </li>
                      <li>
                        <span className='bold-span'>Information collected from other Sources</span>.
                          We may obtain information about you from other sources, such as public databases, joint marketing partners, social media platforms (such as Facebook), as well as from other third parties. 
                      </li>
                    </ul>
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>2. how do we use your information?</span>
                  </h3>
                  <p>
                    We use your personal information for these purposes in reliance on our legitimate business interests (“Business Purposes”), to enter into or perform a contract with you (“Contractual”), with your consent (“Consent”), and/or for compliance with our legal
                    obligations (“Legal Reasons”). We indicate the specific processing grounds we rely on next to each purpose listed below.  
                    <br />
                    <br />
                      <span className='uppercase-span'>We use the information we collect or receive:</span>
                    <br />
                    <ul className='privacy-list'>
                      <br />
                      <li>
                      <span className='bold-span'>To send administrative information to you</span> for our business purposes, and/or for legal reasons. We may use your personal information to send you the product, service, and new feature information and/or information about changes to our terms, conditions, and policies.
                      </li>
                      <li>
                      <span className='bold-span'>Request Feedback</span> for our Business Purposes and/or with your Consent. We may use your information to request feedback and to contact you about your use of our Site.
                      </li>
                      <li>
                      <span className='bold-span'>To protect our Site</span> for Business Purposes and/or Legal Reasons.  We may use your information as part of our efforts to keep our Site safe and secure (for example, for fraud monitoring and prevention).
                      </li>
                      <li>
                      <span className='bold-span'>To respond to legal requests and prevent harm</span> as legally required. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
                      </li>
                      <li>
                      <span className='bold-span'>For other Business Purposes</span>. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Site, products, services, marketing,
                        and experience. We use the user's resume/CV for job searching purposes only.
                      </li>
                    </ul>
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>3. Will your information be shared with anyone?</span>
                  </h3>
                  <p>
                  We only share and disclose your information in the following situations:
                    <br />
                    <ul className='privacy-list'>
                      <br />
                      <li>
                        <span className='bold-span'>Compliance with Laws.</span> We may disclose your information where we are legally required to do so to comply with applicable law, governmental requests, a judicial
                        proceeding, court order, or legal processes, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                      </li>
                      <li>
                        <span className='bold-span'>Vital Interests and Legal Rights.</span> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential
                        violations of our policies, suspected fraud, situations involving potential threats to the safety of any person, and illegal activities, or as evidence in litigation in which we are involved.
                      </li>
                      <li>
                        <span className='bold-span'>Third-Party Advertisers.</span> We may use third-party advertising companies to serve ads when you visit the Site. These companies may use information about your visits
                        to our Site and other websites that are contained in web cookies and other tracking technologies to provide advertisements about goods and services of interest to you. See our Cookie Policy for further information.
                      </li>
                      <li>
                        <span className='bold-span'>Business Partners.</span> We may share your information with our business partners to offer you certain products, services, or promotions.
                      </li>
                      <li>
                        <span className='bold-span'>With your Consent.</span> We may disclose your personal information for any other purpose with your consent.
                      </li>
                      <li>
                        <span className='bold-span'>Other Users.</span> When you share personal information (for example, by posting comments, contributions, or other content to the Site) or otherwise interact with public areas of the Site,
                        such personal information may be viewed by all users and may be publicly distributed outside the Site in perpetuity.
                      </li>
                    </ul>
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>4. Do we use cookies and other tracking technologies?</span>
                  </h3>
                  <p>
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. 
                    <br />
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>5. What is our stance on third party websites?</span>
                  </h3>
                  <p>
                    The Site may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services, or mobile applications.
                    We cannot guarantee the safety and privacy of the data you provide to any third parties. Any data collected by third parties is not covered by this privacy policy.
                    We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that
                    may be linked to or from the Site. You should review the policies of such third parties and contact them directly to respond to your questions.
                    <br />
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>6. How long do we keep your information?</span>
                  </h3>
                  <p>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy unless a longer retention period is required
                    or permitted by law (such as tax, accounting, or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we
                    will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely
                    store your personal information and isolate it from any further processing until deletion is possible.
                    <br />
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>7. How do we keep your information safe?</span>
                  </h3>
                  <p>
                    We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also
                    remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, the transmission of personal
                    information to and from our Site is at your own risk. You should only access the services within a secure environment.
                    <br />
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>8. What are your provacy rights?</span>
                  </h3>
                  <p>
                    Personal Information <br />
                    You may at any time review or change the information by:
                    <ul className='privacy-list'>
                      <br />
                      <li>
                        Contacting us using the contact information provided below
                      </li>
                    </ul>
                    <br />
                    We may delete or change your information, upon your request to delete your information from our active databases. However, some information may be retained in our files to prevent
                    fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use, and/or comply with legal requirements.
                    <br />
                    <span className='bold-span'>Cookies and similar technologies:</span> most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser
                    to remove cookies and reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Site.
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>9. Do we make updates to this policy?</span>
                  </h3>
                  <p>
                    We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible.
                    If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to
                    review this privacy policy frequently to be informed of how we are protecting your information.
                    <br />
                  </p>
                </div>
                <div>
                  <h3>
                    <span className='uppercase-span'>10. How can you contact us about this policy?</span>
                  </h3>
                  <p>
                    If you have questions or comments about this policy, email us at <span className='bold-span'>hello@cande-clothing.com</span>.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end privacy-section */}

      {/* start footer */}

      <Footer />

      {/* end footer */}
    </Fragment>
  );
}

export default PrivacyPolicy;
