import React, { Fragment } from 'react';
import Footer from '../../components/global/Footer';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';
import Instagram from '../../components/global/Instagram';

import './about.css';

/**
 * demo data
 */
import { NavLink } from "react-router-dom";

/**
 * About page
 * @param options
 * @returns {*}
 * @constructor
 */
function About({ options }) {

    return (
        <Fragment>
            <Header options={options} />

            <PageTitle name="About us" />

            {/* start about-section */}
            <section className="about-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-6">
                            <div className="about-area">
                                <div className="img-holder">
                                    <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/_DSC0405-min.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4">
                            <div className="mission-vision-area">
                                <div>
                                    <h3>Our Vision</h3>
                                    <p>
                                        Cande is a men avant-garde fashion brand, which is based in the heart of
                                        Barcelona. Cande main feature is the use of high quality fabrics and
                                        the combination of them with the latest avant-garde fashion trends.
                                    </p>
                                </div>
                                <div>
                                    <h3>Our mission</h3>
                                    <p>
                                        Through unconventional and experimental techniques, Cande creates
                                        unique and revolutionary fashion pieces full of personality, character and
                                        innovation. The brand is characterized by its unique style and the use of the
                                        latest technology in the production process. Cande is a brand that is
                                        constantly evolving and is always looking for new ways to express itself.
                                    </p>
                                </div>
                                <NavLink to="/concept" className="theme-btn">Concept</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end about-section */}

            <Instagram />

            <Fragment>
            {/* start breadcrumb */}
                <section className="page-title">
                    <div className="page-title-container">
                        <div className="page-title-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h3 className="breakline-title">Our <br />Philosophy</h3>
                                    </div>
                                </div>
                            </div>
                            {/* end container */}
                        </div>
                    </div>
                </section>
                {/* end breadcrumb */}
            </Fragment>

            <section className="about-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-12">
                            <div className="mission-vision-area">
                                <div className='philosophy-section'>
                                    <p>
                                        <span className='philosophy-title'>Welcome to Cande, where clothing is more than just a way to cover up - it's an expression of one's soul and character. </span>
                                        <br />
                                        Cande is a clothing brand dedicated to creating clothing that celebrates the uniqueness of the human spirit. Through its designs,
                                        it seeks to embody an individual's unique vision and perspective. With its clothing, Cande strives to reflect the ever-changing
                                        dynamics of life and the chaotic nature of reality.  We are dedicated to creating clothing that not only looks great but also has
                                        a practical purpose - clothing that helps you look good while living your life. Our garments are designed with both style and
                                        function in mind, so you can express yourself through fashion without sacrificing comfort or ease of use. Cande is a brand that
                                        is revolutionizing fashion with its unique style and use of high quality fabrics. Combining experimental techniques with cutting
                                        edge technology, this clothing line creates clothing pieces full of personality, character and innovation. The brand has become
                                        synonymous with luxury and sophistication as it continues to push boundaries in the world of fashion. From classic staples to
                                        statement pieces, this clothing line offers something for everyone who appreciates the artistry behind clothing design. With each
                                        collection exploring new horizons, Cande is sure to continue inspiring trendsetters all over the world.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row philosophy-call-to-action">
                        {/* <NavLink to="/shop-full-width" className="theme-btn">Latest collection</NavLink> */}
                        <NavLink to="/ss24-collection" className="theme-btn">Latest collection</NavLink>
                    </div>
                </div>
            </section>

            <Footer />
        </Fragment>
    );
}

export default About;
