import React, {Fragment} from 'react';

import Footer from '../../components/global/Footer';
import Instagram from '../../components/global/Instagram';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';
import Images from '../../components/lookbook/Images';

/**
 * lookbook data
 */

import data from '../../data/lookbooks.json';
const products = [...data];

/**
 * FW2324 Collection page
 * @param options
 * @returns {*}
 * @constructor
 */
function FW2324Collection({ options }) {

    return (
        <Fragment>

            <Header options={options} />

            <PageTitle name="FW23/24 Collection"/>


            {/* start shop-section */}
            <section className="shop-section shop-style-2 section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="shop-area clearfix">
                                <div className="ecom-content-wrap">
                                    <div className="ecom-content-inner">
                                        <div className="ecom-toolbar-top">
                                            <p className="ecom-result-count">
                                                Showing all outfits
                                            </p>
                                        </div>

                                        <Images
                                          products={products}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container */}
            </section>
            {/* end shop-section */}

            <Instagram/>
            <Footer/>
        </Fragment>
    );
}

export default FW2324Collection;
