import React, {Fragment} from 'react';
import featuredTalesData from '../../data/featured-tales.json';
import {Link} from "react-router-dom";

/**
 * Featured Collections Component
 * @returns {*}
 * @constructor
*/

function featuredTales() {
  return (
    <Fragment>
      {/* start featured-proeducts-section-s2 */}
      <section className="featured-proeducts-section-s2 section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-xs-12">
              <div className="product-grids clearfix">
                {
                  featuredTalesData.map((item, index) => (
                    <div key={index} className="grid">
                      <div className="img-holder">
                          <img loading="lazy" src={process.env.PUBLIC_URL + item.img} alt=""/>
                      </div>
                      <div className="details">
                        <h3>{item.title}</h3>
                        <Link className="shop-btn" to={item.link}>
                          Discover More
                        </Link>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        {/* end container-1410 */}
      </section>
      {/* end featured-proeducts-section-s2 */}
    </Fragment>
  );
}


export default featuredTales;
