import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

/**
 * CTA action display on default home page
 * @returns {*}
 * @constructor
 */
function CtaSection() {

    return (
        <Fragment>
            {/* start cta-section */}
            <section className="cta-section">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="content-area">
                                <span>Coming Soon</span>
                                <h3>Shop online with us</h3>
                                <p>
                                    We are currently working on our online store. Please check back soon.
                                </p>
                                <Link className="theme-btn-s2" to="/contact">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end cta-section */}
        </Fragment>
    );
}

export default CtaSection;
