import React, { Fragment } from 'react';
import Footer from '../../components/global/Footer';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';

import './terms.css';

/**
 * demo data
 */
import { NavLink } from "react-router-dom";

/**
 * Terms & Conditions page
 * @param options
 * @returns {*}
 * @constructor
 */
function TermsConditions({ options }) {

  return (
    <Fragment>
      <Header options={options} />

      <PageTitle name="Terms & Conditions" />

      {/* start terms-section */}
      <section className="terms-section section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-12">
              <div className="terms-list-area">
                <div>
                  <h3 className='top-header'>Effective Date: 31st January 2023</h3>
                  <p className='top-paragraph'>
                    <span className='italic-span'>Site covered: https://cande-clothing.com</span>
                    <br />
                  </p>
                  <p>
                    <span className='uppercase-span bold-span'>The Agreement:</span> The use of this website and services on this website provided by Cande-clothing.com (hereinafter referred to as "Website") are subject to the following Terms & Conditions (hereinafter the "Agreement"),
                    all parts and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the use of all pages on this website (hereinafter collectively referred to as "Website") and any services provided by or on this Website ("Services").
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>1) Definitions</span>
                    <br />
                    <br />
                  </p>
                  <ul className='terms-list'>
                    <li>
                      <p>
                        <span className='bold-span'>“Agreement”</span> refers to this Terms and Conditions and the Privacy Policy and other documents provided to you by the Website;
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='bold-span'>“We”, “us”</span> and <span className='bold-span'>“Our”</span> refers to this Terms and Conditions and the Privacy Policy and other documents provided to you by the Website;
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='bold-span'>“User”, “You”</span> and <span className='bold-span'>“your”</span> efers to the person who is accessing or visiting our website for taking or availing of any service from us.
                        User shall include the company, partnership, sole trader, person, body corporate or association taking services of this Website;
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='bold-span'>“Website”</span> shall mean and include https://cande-clothing.com/; and any successor Website of the Company or any of its affiliates;
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='bold-span'>Parties:</span> Collectively, the parties to this Agreement (We and You) will be referred to as Parties.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>2) Assent & Acceptance</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    By using the Website, you warrant that you have read and reviewed this Agreement and that you agree to be bound by it. If you do not agree to be bound by this Agreement, please leave the Website immediately.
                    We only agree to provide the users of this Website and Services to you if you assent to this Agreement.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>3) SERVICE</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    <span className='italic-span'>At cande-clothing.com, we offer you a meticulously designed website where we provide information about men avant-garde fashion brands.</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>4) Age restriction</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    You must be at least 13 (Thirteen) years of age to use this Website or any Services contained herein. By using this Website, you represent and warrant that you are at least 13 years of age and may legally agree
                    to this Agreement. We assume no responsibility or liability for any misrepresentation of your age.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>5) Licence to use the website</span>
                    <br />
                    <br />
                  </p>
                  <p>
                  We may provide you with certain information as a result of your use of the Website or Services. Such information may include but is not limited to, documentation, data, or information developed by us, and other
                  materials that may assist in your use of the Website or Services ("Our Materials"). Subject to this Agreement, we grant you a non-exclusive, limited, non-transferable, and revocable license to use Our Materials
                  solely in connection with your use of the Website and Services. Our Materials may not be used for any other purpose, and this license terminates upon your cessation of use of the Website or Services or at the
                  termination of this Agreement.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>6) General conditions</span>
                    <br />
                    <br />
                  </p>
                  <ul className='terms-list'>
                    <li>
                      <p>
                        Website does not guarantee the accuracy, completeness, validity, or timeliness of the information listed by us.
                      </p>
                    </li>
                    <li>
                      <p>
                      Website makes material changes to these terms and conditions from time to time, Website may notify you either by prominently posting a notice of such changes or via email communication.
                      </p>
                    </li>
                    <li>
                      <p>
                        The website is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Service for your private, personal, non-commercial use, subject to all
                        the terms and conditions of this Agreement as they apply to the Service.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>7) intellectual property</span>
                    <br />
                    <br />
                    You agree that the Website and all Services provided by us are the property of Cande-Clothing, including all copyrights, trademarks, trade secrets, patents, and other intellectual property ("Our IP").
                    You agree that we own all right, titles, and interests in and to Our IP and that you will not use Our IP for any unlawful or infringing purpose. You agree not to reproduce or distribute Our IP in any way,
                    including electronically or via registration of any new trademarks, trade names, service marks, or Uniform Resource Locators (URLs), without express written permission from us.
                  </p>
                  <br />
                  <ul className='terms-list'>
                    <li>
                      <p>
                        To make the Website and Services available to you, you hereby grant us a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content you publish,
                        upload, or otherwise make available to the Website ("Your Content"). We claim no further proprietary rights in your Content.
                      </p>
                    </li>
                    <li>
                      <p>
                        If you feel that any of your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of our users, please contact us and let us know.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>8) user obligations</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    As a user of the Website or Services, you may be asked to register with us. When you do so, you will choose a user identifier, which may be your email address or another term, as well as a password. You may also
                    provide personal information, including, but not limited to, your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable you to use the Website and Services.
                    You must not share such identifying information with any third party, and if you discover that your identifying information has been compromised, you agree to notify us immediately in writing. An email notification
                    will suffice. You are responsible for maintaining the safety and security of your identifying information as well as keeping us apprised of any changes to your identifying information. Providing false or inaccurate
                    information, or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>9) acceptable use</span>
                    <br />
                    <br />
                    You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services,
                    or general business of Cande-Clothing.
                    <br />
                    <br />
                    <span className='uppercase-span'>You further agree not to use the Website or Services:</span>
                  </p>
                  <br />
                  <ul className='terms-list'>
                    <li>
                      <p>
                        To harass, abuse, or threaten others or otherwise violate any person's legal rights;
                      </p>
                    </li>
                    <li>
                      <p>
                        To violate any of our intellectual property rights or any third party;
                      </p>
                    </li>
                    <li>
                      <p>
                        To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;
                      </p>
                    </li>
                    <li>
                      <p>
                        To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;
                      </p>
                    </li>
                    <li>
                      <p>
                        To publish or distribute any obscene or defamatory material;
                      </p>
                    </li>
                    <li>
                      <p>
                        To publish or distribute any material that incites violence, hate, or discrimination towards any group;
                      </p>
                    </li>
                    <li>
                      <p>
                        To unlawfully gather information about others.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>10) assumption of risk</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    The Website and Services are provided for communication purposes only. You acknowledge and agree that any information posted on Our Website is not intended to be legal advice, medical advice, or financial advice, and no
                    fiduciary relationship has been created between you and us. You further agree that your purchase of any of the products on the Website is at your own risk. We do not assume responsibility or liability for any advice or other
                    information given on the Website.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>11) Reverse engineering & security</span>
                    <br />
                    <br />
                    You agree not to undertake any of the following actions:
                  </p>
                  <ul className='terms-list'>
                    <li>
                      <p>
                        Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website or Services;
                      </p>
                    </li>
                    <li>
                      <p>
                        Website makes material changes to these terms and conditions from time to time, Website may notify you either by prominently posting a notice of such changes or via email communication.Violate the security of the Website or
                        Services through any unauthorized access, circumvention of encryption or other security tools, data mining, or interference with any host, user, or network.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>12) indemnification</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    You agree to defend and indemnify us and any of our affiliates (if applicable) and hold us harmless against any legal claims and demands, including reasonable attorney's fees, which may arise from or relate to your use or misuse of
                    the Website or Services, your breach of this Agreement, or your conduct or actions. You agree that we shall be able to select its legal counsel and may participate in its defense if we wish.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>13) exclusion of liability</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    You understand and agree that we (A) do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties; and (B) shall not be responsible for any materials posted by us or any third party.
                    You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers and any information provided by us or any third party. Further, we shall not be liable for direct, indirect consequential, or any other
                    form of loss or damage that may be suffered by a user through the use of the www.cande-clothing.com Website including loss of data or information or any kind of financial or physical loss or damage. In no event shall Cande-Clothing, nor its
                    owners, directors, employees, partners, agents, suppliers, or affiliates, be accountable for any indirect, incidental, special, eventful, or exemplary costs, including without limitation, loss of proceeds, figures, usage, goodwill, or other
                    intangible losses, consequential from (i) your use or access of or failure to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content attained from the Service; and (iv) unlawful access,
                    use or alteration of your transmissions or content, whether or not based on guarantee, agreement, domestic wrong (including carelessness) or any other lawful concept, whether or not we've been aware of the possibility of such damage, and even
                    if a cure set forth herein is originated to have futile of its important purpose.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>14) release</span>
                    <br />
                    <br />
                  </p>
                  <p>
                  You release the Company and our successors from all losses, damages, rights, demands, and actions of any kind, including personal injuries, death, and property damage, that are directly or indirectly related to or arising from your use of the
                  Services (collectively, “Claims”).
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>15) no responsibility</span>
                    <br />
                    <br />
                    We are not responsible to you for:
                  </p>
                  <ul className='terms-list'>
                    <li>
                      <p>
                        any reliance that you may place on any material or commentary posted on our website. Please note that nothing contained in our website or the material published on it is intended to amount to advice on which you should rely; or
                      </p>
                    </li>
                    <li>
                      <p>
                        any losses you suffer because you cannot use our website at any time; or
                      </p>
                    </li>
                    <li>
                      <p>
                        any errors in or omissions from our website; or
                      </p>
                    </li>
                    <li>
                      <p>
                        any unauthorized access or loss of personal information that is beyond our control.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>16) spam policy</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    You are strictly prohibited from using the Website or any of our's Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>17) third-party links & content</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    We may occasionally post links to third-party websites or other services. You agree that we are not responsible or liable for any loss or damage caused as a result of your use of any third-party services linked to Our Website. We are not
                    responsible for the content, accuracy, or opinions expressed in any third-party websites or services. You agree that we are not responsible or liable for any loss or damage caused as a result of your use of any third-party services linked to Our
                    Website.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>18) modification & variation</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    We may, from time to time and at any time without notice to you, modify this Agreement. You agree that we have the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement
                    are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement unless prior versions are specifically referred to or incorporated into the latest modification
                    or variation of this Agreement.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>19) entire agreement</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    This Agreement constitutes the entire understanding between the Parties concerning any use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>20) service interruptions</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that
                    we shall have no liability for any damage or loss caused as a result of such downtime.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>21) term, termination & suspension</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    We may terminate this Agreement with you at any time for any reason, with or without cause. We specifically reserve the right to terminate this Agreement if you violate any of the terms outlined herein, including, but not limited to, violating the intellectual property
                    rights of us or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If you have registered for an account with Us, You may also terminate this Agreement at any time by contacting Us and
                    requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>22) no warranties</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    You agree that your use of the Website and Services is at your sole and exclusive risk and that any Services provided by Us are on an "As Is" basis. We hereby expressly disclaim any express or implied warranties of any kind, including, but not limited to the implied warranty
                    of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website or Services will meet your needs or that the Website or Services will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability
                    or accuracy of any information on the Website or obtained through the Services. You agree that any damage that may occur to you, through your computer system, or as a result of the loss of your data from your use of the Website or Services is your sole responsibility and that
                    we are not liable for any such damage or loss.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>23) limitation on liability</span>
                    <br />
                    <br />
                  </p>
                  <p>
                    We are not liable for any damages that may occur to you as a result of your use of the Website or Services, to the fullest extent permitted by law. This section applies to any claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages,
                    negligence, strict liability, fraud, or torts of any kind.
                  </p>
                </div>
                <div>
                  <p>
                    <span className='uppercase-span bold-span'>24) general provisions:</span>
                    <br />
                    <br />
                  </p>
                  <ul className='terms-list'>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>jurisdiction, venue & choice of law:</span> The terms herein will be governed by and construed following the law of Spain without giving effect to any principles of conflicts of law. The Courts of Spain‎ shall have exclusive jurisdiction
                        over any dispute arising from the use of the Website.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>assignment:</span> This Agreement, or the rights granted hereunder, may not be assigned, sold, leased, or otherwise transferred in whole or part by you. Should this Agreement, or the rights granted hereunder, be assigned, sold, leased, or otherwise
                        transferred by us, the rights and liabilities of the Cande-Clothing will bind and inure to any assignees, administrators, successors, and executors.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>severability:</span> If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such a condition, the
                        remainder of this Agreement shall continue in full force.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>no waiver:</span> If we fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or any other provision. Waiver of any part or sub-part of this Agreement will not constitute a
                        waiver of any other part or sub-part.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>headings for convenience only:</span> Headings of parts and sub-parts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>no agency, partnership, or joint venture:</span> No agency, partnership, or joint venture has been created between the Parties as a result of this Agreement. No Party has any authority to bind the other to third parties.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>force majeure:</span> We are not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature, and
                        natural disasters, and other acts which may be due to unforeseen circumstances.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className='uppercase-span bold-span'>electronic communication permitted:</span> Electronic communications are permitted to both Parties under this Agreement, including e-mail or fax. For any questions or concerns, please email us at the following address: <span className='bold-span'>
                          hello@cande-clothing.com.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end terms-section */}

      {/* start footer */}

      <Footer />

      {/* end footer */}
    </Fragment>
  );
}

export default TermsConditions;
