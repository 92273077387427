import React, {Fragment} from 'react';

/**
 * product Quick View component
 * @param data
 * @param onQuickViewCloseClick
 * @returns {*}
 * @constructor
 */
function QuickView({data, onQuickViewCloseClick}) {
    const dataPrice = data.price;
    return (
        <Fragment>
            <div className="quick-view-single-product activve-quick-view-single-product">
                <div className="view-single-product-inner clearfix">
                    <button className="btn quick-view-single-product-close-btn" onClick={onQuickViewCloseClick}><i
                        className="pe-7s-close-circle"/></button>
                    <div className="img-holder">
                        <img loading="lazy" src={process.env.PUBLIC_URL + data.mainImg} alt=""/>
                    </div>
                    <div className="product-details">
                        <h4>{data.title}</h4>
                        {dataPrice ?
                            <div className="price">
                                
                                    <span className="current">{data.Symbol}{data.price}</span>
                                    {
                                        parseInt(data.price) < parseInt(data.oldPrice) ?
                                        <span className="old">{data.Symbol}{data.oldPrice}</span> : ''
                                    }

                            </div> : ''
                        }
                        <p>{data.content}</p>
                        {/* <div className="product-option">
                            <form className="form">
                                <div className="product-row">
                                    <div>
                                        <input className="product-count" type="text" defaultValue={1}
                                               name="product-count-3"/>
                                    </div>
                                    <div>
                                        <button type="submit" onClick={onQuickViewCloseClick}>Add to cart</button>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                        {/* <div className="thb-product-meta-before">
                            <div className="product_meta">
                                <span className="sku_wrapper">SKU: <span className="sku">{data.SKU}</span></span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default QuickView;
