import React, { Fragment, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';

import Footer from '../../components/global/Footer';
import Instagram from '../../components/global/Instagram';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';


/**
 * ContactUs page
 * @param options
 * @returns {*}
 * @constructor
 */
function ContactUs({ options }) {

    const [buttonState, setButtonState] = useState('Send Message');
    const [statusMessage, setStatusMessage] = useState("");
    const formik = useFormik({
        initialValues: {
            from_name: '', //user name
			to_name: process.env.REACT_APP_ADMIN_EMAIL, //email id of the admin
			subject: '', // subject of email
			reply_to: '', // user email
			message: '' // message of email
        },
        validationSchema: Yup.object({
			from_name: Yup.string()
			.required('* Name field is required'),
			subject: Yup.string()
			.required('* Subject field is required'),
			reply_to: Yup.string().email('Invalid email address')
			.required('* Email field is required'),
			message: Yup.string().required('* Message field is required')
		}),
        onSubmit: (values, { resetForm, setSubmitting }) => {
			try{
			emailjs.send(process.env.REACT_APP_SECURE_ID , process.env.REACT_APP_TEMPLATE_ID, values, process.env.REACT_APP_USER_ID)
				.then(() => {
					toast.success('Message sent successfully', { position: 'top-left' });
                    setStatusMessage("Message sent successfully");
					setButtonState('Send Email');
					setSubmitting(false);
					resetForm();
				});
			}
			catch {
				toast.error('Message unsent', { position: 'top-left' });
                setStatusMessage("Message unsent");
				setButtonState('Send Email');
				setSubmitting(false);
			}
		},
    });

    /**
     * demo data
     */
    const contactUsData = {
        address: "Barcelona, Spain",
        email_1: "hello@cande-clothing.com",
        time: "10AM - 5 PM"
    };

    return (
        <Fragment>
            <Header options={options} />

            <PageTitle name="Contact"/>

            {/* start contact-section */}
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <section className="contact-section contact-pg-section section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col-lg-offset-1">
                            <div className="contact-info col-lg-5">
                                <ul>
                                    <li>
                                        <i className="pe-7s-culture"/>
                                        <h4>Office address</h4>
                                        <p>{contactUsData.address}</p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-mail"/>
                                        <h4>Email us</h4>
                                        <p>{contactUsData.email_1}</p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-alarm"/>
                                        <h4>Opening hours</h4>
                                        <p>{contactUsData.time}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact-form-col col-lg-7">
                                <h2>Send us a message</h2>
                                <div className="contact-form">
                                    <form className="contact-validation-active" id="contact-form-main" onSubmit={formik.handleSubmit}>
                                        <div  className="">
                                            <div  className="contact-option">
                                                <label htmlFor="from_name" />
                                                <input
                                                    id="from_name"
                                                    name="from_name"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="YOUR NAME"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.from_name}
                                                />
                                                <div className={`expandable ${formik.touched.from_name && formik.errors.from_name ? 'show' : ''}`}>
                                                    {formik.errors.from_name}
                                                </div>
                                            </div>
                                            <div  className="contact-option">
                                                <label htmlFor="subject" />
                                                <input
                                                    id="subject"
                                                    name="subject"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="SUBJECT"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.subject}
                                                />
                                                <div className={`expandable ${formik.touched.subject && formik.errors.subject ? 'show' : ''}`}>
                                                    {formik.errors.subject}
                                                </div>
                                            </div>
                                            <div  className="contact-option">
                                                <label htmlFor="reply_to" />
                                                <input id="reply_to" type="email" 
                                                    name="reply_to" placeholder="YOUR EMAIL"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.reply_to}
                                                />
                                                <div className={`expandable ${formik.touched.reply_to && formik.errors.reply_to ? 'show' : ''}`}>
                                                    {formik.errors.reply_to}
                                                </div>
                                            </div>
                                            <div  className="contact-option">
                                                <label htmlFor="message" />
                                                <textarea id="message" name="message" placeholder="YOUR MESSAGE"
                                                    autoComplete="off" onChange={formik.handleChange}
                                                    value={formik.values.message}
                                                />
                                                <div className={`expandable ${formik.touched.message && formik.errors.message ? 'show' : ''}`}>
                                                    {formik.errors.message}
                                                </div>
                                            </div>
                                            <div  className="contact-option">
                                                <button disabled={formik.isSubmitting} type="submit" className="tp-btn-yellow">
                                                <span>{buttonState}</span>
                                            </button>
                                        </div>
                                        <div  className="col-12 form-message">
                                            <span id='output'  className="output_message text-center text-uppercase">
                                                {statusMessage}
                                            </span>
                                        </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end contact-section-s3 */}

            {/*  start contact-map */}
            <section className="contact-map-section">
                <h2 className="hidden">Contact map</h2>
                <div className="contact-map">
                    <iframe
                        title="Contact map"
                        allowFullScreen
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2356.829345558885!2d2.1779890468264003!3d41.38432118317993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sit!4v1674059258802!5m2!1sen!2sit" 
                    />
                </div>
            </section>
            {/* end contact-map */}

            <Instagram/>
            <Footer/>
        </Fragment>
    );
}

export default ContactUs;
