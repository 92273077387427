import React, { Fragment } from 'react';
import Footer from '../../components/global/Footer';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';
import CtaSection from '../../components/CTA/CtaSection';

import './wholesale.css';

/**
 * demo data
 */
import { NavLink } from "react-router-dom";

/**
 * Wholesale page
 * @param options
 * @returns {*}
 * @constructor
 */
function Wholesale({ options }) {

    return (
        <Fragment>
            <Header options={options} />

            <PageTitle name="Wholesale & B2B" />

            {/* start wholesale-section */}
            <section className="wholesale-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-5">
                            <div className="wholesale-area">
                                <div className="img-holder">
                                    <img loading="lazy" src={process.env.PUBLIC_URL + "/assets/images/ss24/DSC05373.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-5">
                            <div className="mission-vision-area">
                                <div>
                                    <h3>Multibrand & Retail</h3>
                                    <p>
                                      We are excited to introduce our avant-garde men's fashion brand to you, and we look forward to partnering with
                                      you to bring our unique aesthetic to your customers. Our brand is all about pushing the boundaries of traditional
                                      fashion and creating truly unique, eye-catching looks that are sure to stand out.
                                      At Cande, we pride ourselves on the quality and craftsmanship of our products. Each piece is carefully
                                      designed and made with the finest materials to ensure that it not only looks great but also lasts for years to come.
                                      Our collections are inspired by the latest trends in men's fashion, but with our own unique twist that sets us apart
                                      from the competition.
                                      We believe that our brand would be a perfect fit for avantgarde concept store. Our collection include a wide range of
                                      products, from statement pieces to versatile basics, so you can mix and match to create a look that's perfect for your
                                      customers. And with our commitment to quality and craftsmanship, you can be confident that our products will meet your
                                      customers' high expectations.
                                      We offer competitive pricing and terms for our wholesale partners, along with sales support and marketing materials to
                                      help you effectively promote our products in your store. We also provide high-quality product samples, so you can see
                                      and feel the quality of our products before you make a purchase.
                                      We are excited to begin this partnership with you and look forward to working together to bring our unique aesthetic to
                                      your customers. Please don't hesitate to reach out if you have any questions or if there is anything we can do to help
                                      support your business.
                                    </p>
                                </div>
                                <div>
                                    <h3>SS 24</h3>
                                    <p>
                                        In the vast expanse of time, where legends and tales are woven like intricate threads, Cande's SS24 collection emerges as a
                                        testament to the indomitable spirit of the Vikings and their deep connection with nature. Inspired by the fearless warriors and the
                                        unwavering strength that defined the Vikings, this collection becomes a bridge not only between ancient Nordic heritage and
                                        the modern world but also between the untamed forces of nature and the unyielding human spirit. 
                                    </p>
                                </div>
                                <NavLink to="/contact" className="theme-btn">Experience the Cande difference for yourself</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NavLink to="/contact" className="theme-btn initial-statement">If you're a Concept Store, Boutique, or Retailer</NavLink>

            {/* end wholesale-section */}

            <NavLink to="/contact" className="theme-btn closing-statement">
              we would love to hear from you and discuss how we can work together to bring our vision to a wider audience.
            </NavLink>

            <Footer />
        </Fragment>
    );
}

export default Wholesale;
