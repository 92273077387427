import React, {Fragment} from 'react';

/**
 * Images component
 * @param products
 * @param ordering
 * @returns {*}
 * @constructor
 */

function Images({products, ordering}) {
  const productsList = products.map((item, _index) => {
    return (
      // return an array containing all the images contained in the item
      item.images.map((image, index) => {
        return (
          <li key={index + 1} className="product">
            <div className="product-holder">
              <img loading="lazy" src={process.env.PUBLIC_URL + image} alt=""/>
            </div>
          </li>
        )
      })
    )
  })

  return (
    <Fragment>
      <ul className={"products " + (ordering == 1 ? 'default-column' : ordering == 2 ? 'three-column' : ordering == 3 ? 'list-view' : '')}>
        {productsList}
      </ul>
    </Fragment>
  )
}

export default Images;
